<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <b-card>
        <div class="contact-box center-version">
          <div class="top">
            <h3 class="m-b-xs">
              <strong> {{ viewData.name }} </strong>
            </h3>
            <div class="font-bold">
              @{{ viewData.user_full_name }}
            </div>
          </div>
          <div class="user-info">
            <ul>
              <li>
                <feather-icon
                  icon="ActivityIcon"
                /> {{ resolveActiveUser(viewData.is_active) }}
              </li>
            </ul>
          </div>
          <div class="contact-box-footer">
            <h5>Other Details</h5>
            <div class="m-t-xs btn-group">
              <ul>
                <li> <strong>Merchant Type Name: </strong>{{ viewData.merchant_type_name }} </li>
                <li> <strong>Client Cashback: </strong>{{ viewData.client_cashback }} </li>
                <li> <strong>Zerocash Cashback: </strong>{{ viewData.zerocash_cashback }} </li>
              </ul>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>
    <meta-data
      :user-address="userAddresses"
      :user-files="userFiles"
      :options="options"
      add-component-name="show-merchant-add-address"
      edit-component-name="show-merchant-edit-address"
    />
  </b-row>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import merchant from '../merchant'
import MetaData from '@/components/MetaData.vue'

export default {
  components: { MetaData },
  setup() {
    const { viewData, resolveActiveUser } = merchant()
    const userAddresses = ref([])
    const userFiles = ref([])

    const options = [
      { value: 'merchant_paper', name: 'Merchant Paper' },
      { value: 'merchant_national_id', name: 'Merchant National ID' },
      { value: 'bank_file', name: 'Bank File' },
    ]
    return {
      viewData,
      resolveActiveUser,
      userAddresses,
      userFiles,
      options,
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    getdata() {
      axios.get(`merchants/${this.$route.params.id}`).then(res => {
        this.viewData = res.data.data
        this.userAddresses = res.data?.data.address
        this.userFiles = res.data?.data.files
      })
    },
  },
}
</script>

<style lang="scss">
/* CONTACTS */
.contact-box {
  padding: 20px;
  margin-bottom: 20px;
}
.contact-box > a {
  color: inherit;
}
.contact-box.center-version {
  padding: 0;
}
.contact-box.center-version > .top {
  display: block;
  padding: 5px;
  text-align: center;
}
.contact-box.center-version > .top img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-box.center-version address {
  margin-bottom: 0;
}
.contact-box .contact-box-footer {
  border-top: 1px solid #e7eaec;
  padding: 15px 20px;
}
a{
    text-decoration:none !important;
}

.user-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
</style>
